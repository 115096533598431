import React from 'react';
import SEO from 'patronscan-theme/src/components/seo';
import { Testimonial } from 'patronscan-theme/src/models/testimonial';
import HomeIndustry from 'patronscan-theme/src/components/sections/home/home-industry';
import DescriptionSection from 'patronscan-theme/src/components/sections/description';
import TrustedBy from 'patronscan-theme/src/components/sections/trusted-by';
import Button from 'patronscan-theme/src/components/buttons/button';
import WhyPatronscan from 'patronscan-theme/src/components/sections/why-patronscan';
import PrivacyCommitment from 'patronscan-theme/src/components/sections/privacy';
import { Route } from '../../../../theme/src/models/site-links';
import HeroBanner from 'patronscan-theme/src/components/sections/hero-banner';
import './index.css';
import { useStaticQuery, graphql } from 'gatsby';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "hero-images/fake-ID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logos: allFile(filter: { relativeDirectory: { eq: "logos/home" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const industries = [
    {
      name: 'Bar and Nightclub',
      description: 'Validate IDs, make the nightlife safer, protect your venue and your license.',
      link: '/id-scanner-for-bars',
    },
    {
      name: 'Property Management',
      description: 'Verify your customer, reduce loss from fraud. Stay compliant.',
      link: '/industry/property-management',
    },
    {
      name: 'Telecom',
      description: 'Minimize fraud, protect profits and enhance your client experience.',
      link: '/industry/telecom',
    },
    {
      name: 'Automotive',
      description: 'Stop car theft, deter known fraudsters, validate your customers in seconds.',
      link: '/industry/vehicle-rental',
    },
  ];
  const testimonials: Testimonial[] = [
    {
      name: 'Chris "Sarge" Curtis',
      role: 'Director of the Las Vegas Downtown Rangers',
      testimonial:
        'The best decision we made was implementing Patronscan into our security and safety process, we saw a dramatic decrease in fights and disturbances immediately.',
    },
    {
      name: 'Jesse James',
      role: 'Member of Alberta Safer Bars Council and owner of Union Hall Nightclub',
      testimonial:
        'Patronscan has been a great asset to our business. It has certainly mitigated security and liability risks… I would definitely recommend the system to anyone!',
    },
    {
      name: 'Sergio Maione',
      role: 'Owner of Empire Ballroom, Whiskey Jacks',
      testimonial:
        'Patronscan and their ID scanning software is the best on the market and, in my opinion, has greatly reduced the amount of incidents for us and everyone that is currently using it, as it makes each individual patron accountable for their actions.',
    },
    {
      name: 'Joe Gregory',
      role: 'Owner of the Aurora Nightclub and The HooDoo Lounge',
      testimonial:
        'Banff is a tourist destination that gets a lot of international IDs and the Patronscan ID scanning system has been able to scan the multitude of IDs that we encounter accurately and efficiently.',
    },
    {
      name: 'Nadr Mavi',
      role: 'Owner of Monroe San Francisco',
      testimonial:
        'We have looked at various ID scanning providers… We have used the ID scanner from Patronscan and have found it to be the most advanced and effective product in the market.',
    },
    {
      name: 'Darryl Kostash',
      role: 'Director of Operations at Hudsons Canadian Hospitality',
      testimonial:
        'With Patronscan, we’re able to use the automated daily/weekly/monthly reports they send out to analyze data and the demographics of our patrons. This has helped us to expand our brand and become the most successful pub destination in Canada.',
    },
    {
      name: 'Jeff Steiert',
      role: "Manager at Ralph's Texas Bar and Steakhouse",
      testimonial:
        'An awesome feature is that it allows us to identity poor behaved individuals throughout the night who have fought, damaged property, or even walked out on a tab.  We simply match their appearance to the photo taken, and from this step it is easy to decide whether we call the cops, flag them, or add a warning for the next time they visit the bar.',
    },
    {
      name: 'Ryan Wise',
      role: 'General Manager at Murmur Nightclub',
      testimonial:
        'Having access to the flag network eliminates issues before they can even begin. We are notified immediately if the patrons are problematic and can deny them access to all of our venues.',
    },
    {
      name: 'Bruce Wahl',
      role: 'Security Manager at Granville Entertainment',
      testimonial:
        'I researched patron identification systems and flag and alert systems. We had a previous system but, it had fallen behind… We presently use four units in our venues and have found them to be user friendly and accurate in detecting false identification documents from around the world.',
    },
    {
      name: 'Susan Durst',
      role: 'Owner of The Press Club',
      testimonial:
        "I just want my customers to be safe, my friends to be safe and although I don't work for Patronscan, I just love your product.",
    },
  ];
  return (
    <div>
      <SEO title="Home" />
      <HeroBanner
        heroImage={data.desktopImage.childImageSharp.fluid}
        heading="Patronscan is the UK's best ID scanner"
        subHeading="Creating safe environments and trustworthy relationships through identity verification."
        headingText=""
        showVideo={true}
        videoLink={'https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1'}
        linkText="Pick Industry"
        link={Route.Industries}
        buttonId="UKHomeHero"
      />
      <HomeIndustry industries={industries} />
      <DescriptionSection bgColor="#FAFAFA">
        <h2 className="text-4xl text-grey-dark font-heading font-hairline my-8 lg:text-5xl">
          Do I Need Patronscan?
        </h2>
        <p className="text-grey-darker font-thin my-4">
          Patronscan is a business essential tool that can identify individuals that may have
          criminal or malicious intent by scanning the individual’s government-issued identification
          document.
        </p>
        <p className="text-grey-darker font-thin my-4">
          Scanning identification documents reduces the risk and is a deterrent for anti-social
          behavior, theft, and other crimes.
        </p>
        <p className="text-grey-darker font-thin my-4">
          This increases staff and other visitors’ confidence in your establishment while assisting
          law enforcement agencies to catch those responsible for committing an offence.
        </p>
        <h2 className="text-4xl text-blue font-heading font-hairline my-8 lg:text-5xl">
          Patronscan - Your Front Line
        </h2>
        <Button
          buttonId="UKHomeDescription"
          internal={true}
          text="Get Started"
          blue={true}
          link={Route.GetPricing}
        />
      </DescriptionSection>
      <TrustedBy logos={data.logos.edges} testimonials={testimonials} />
      <WhyPatronscan backgroundColor="#fafafa" />
      <PrivacyCommitment showCta={true} />
    </div>
  );
};

export default IndexPage;
